import eventsHandler from '@/services/common/eventsHandler.js'

class jobForm extends eventsHandler {
  constructor() {
    super()
    this._job = {}
    this._valid = false
  }

  get job() {
    const job = {
      ...this._job
    }
    return job
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._job.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetJob() {
    this._job = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {job: {}})
  }

  updateField(key, value) {
    this._job[key] = value
    this._executeCallbacksOf('update', {job: this._job})
    this._executeCallbacksOf('update-' + key, {key: this._job[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(job) {
    this._job = job
    this._executeCallbacksOf('update', {job: this._job})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new jobForm()